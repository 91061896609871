<nav class="navbar navbar-expand-lg navbar-dark bg-dark">
	<img src="/assets/img/bitmap3x81.png" style="width:25px;margin-right: 5px;"><a class="navbar-brand" href="#">Callhealer</a>
	<button class="navbar-toggler" type="button" (click)="collapse.toggle()">
		<span class="navbar-toggler-icon"></span>
	</button>
	<div class="collapse navbar-collapse" #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed">
		<ul class="navbar-nav mr-auto">
			<!-- <li class="nav-item">
				<a class="nav-link">
					<i class="fa fa-user mr-2"></i>
					My Profile
				</a>
			</li> -->
			<li class="nav-item">
				<a class="nav-link" (click)="signOut()">
					<i class="fa fa-sign-out mr-2"></i>
					Sign Out
				</a>
			</li>
		</ul>
		<form class="user-logo form-inline my-2 my-lg-0" *ngIf="authService.userData as user">
			<img class="img rounded-circle mr-2" src="{{profilresim}}">
			<span>{{ name }}</span>
			<!-- <p>User ID: <strong>{{user.uid}}</strong></p>
            <p>Email: <strong>{{user.email}}</strong></p>
            <p class="mb-0">Email Verified: <strong>{{user.emailVerified}}</strong></p> -->
		</form>
	</div>
</nav>
<div class="container-xl">
    <div class="title-number justify-content-center align-items-center col-12 py-3 px-0">
        <div class="alert alert-success text-align-center m-0">
            <h4 class="mb-0">Welcome to Callhealer Meeting Room, When you are ready, you can start to call</h4>
        </div>
        <div class="alert alert-danger m-0 mt-3" *ngIf="errorMessage?.length">
            {{ errorMessage }}
        </div>
        <div class="alert alert-info m-0 mt-3" *ngIf="infoMessage?.length">
            {{ infoMessage }}
        </div>
    </div>
	<div class="main-body d-flex flex-wrap">
        <div class="main col-xl-8 pl-xl-0 pr-xl-3 col-lg-8 pl-lg-0 pr-lg-3 col-12 p-0 pb-3 border-right">
            <h5 class="alert alert-primary d-flex align-items-center">
                <i class="far fa-video mr-2"></i>
                Video
            </h5>
            <div class="dialer d-flex">
                <div class="input-group mr-2" style="display:none;">
                    <div class="input-group-prepend">
                      <div class="input-group-text"><i class="far fa-phone-office"></i></div>
                    </div>
                    <input type="number" class="form-control" placeholder="Call Number" [(ngModel)]="callNumber" required>
                </div>
                <button type="button" class="btn btn-primary mr-2" (click)="callButton(callNumber)" 
                    [disabled]="!callNumber || callConnected || callNumber === myNumber">
                    <i class="far fa-phone mr-1"></i>Start Videocall with {{opponent}}
                </button>
                <button type="button" class="btn btn-danger mr-2" (click)="hangUp()" 
                    [disabled]="!callConnected">
                    <i class="far fa-phone-slash mr-1"></i> Hangup
                </button>
                <button type="button" class="btn btn-success" (click)="answerCall()" *ngIf="incomingCall">
                    <i class="far fa-phone-volume mr-1"></i> Answer
                </button>
            </div>
            <div class="videos mt-3" [hidden]="!callConnected" #fullScreen>
                <div class="local-video">
                    <video class="rounded" #remoteVideoStream autoplay playsinline></video>
                </div>
                <div class="remote-video">
                    <video class="rounded" #localVideoStream autoplay playsinline></video>
                </div>
                <div class="actions">
                    <div class="btn btn-primary rounded-circle mr-3" (click)="openFullscreen()"><i class="far fa-expand"></i></div>
                    <div class="btn btn-danger rounded-circle" (click)="hangUp()" ><i class="far fa-phone-slash"></i></div>
                </div>
            </div>
        </div>
        <div class="chat col-xl-4 pl-xl-3 pr-xl-0 col-lg-4 pl-lg-3 pr-lg-0 col-12 p-0 pb-3">
            <h5 class="alert alert-primary d-flex align-items-center">
                <i class="far fa-comment-alt mr-2"></i>
                Chat 
            </h5>
            <h6 class="alert alert-success" *ngIf="callConnected">
                <i class="far fa-user-alt mr-2"></i>
                Call Connected to 
                <span class="text-danger">
                    {{callerNumber?.length ? callerNumber : connectedCallNumber }}
                </span>
            </h6>
            <div class="form-group">
                <input type="text" class="form-control" placeholder="Write Message" [(ngModel)]="chatMessage" *ngIf="callConnected"
                    (keydown.enter)="sendMessage(chatMessage, authService.userData)" required>
            </div>
            <div class="messages form-control" *ngIf="chatMessages.length">
                <div class="message" [class.mb-2]="i !== chatMessages.length - 1" *ngFor="let message of chatMessages; let i = index" [class]="message.class">
                    <div class="message-body">
                        <span class="user"><i class="fa fa-user mr-2"></i>{{ message.user }} ({{ message.number }}) </span>
                        <span> {{ message.text }} </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>