// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    firebase: {
        apiKey: "AIzaSyBZgOPFK0tMohrmy5RgFIM_BK7BmAqTUSo",
  authDomain: "polikliniksepeti-34f54.firebaseapp.com",
  projectId: "polikliniksepeti-34f54",
  storageBucket: "polikliniksepeti-34f54.appspot.com",
  messagingSenderId: "122419508036",
  appId: "1:122419508036:web:18843d6bc740e1b497dd11",
  measurementId: "G-M6G12BVPGL"
    }
};

// Refer here to create API Keys https://fireship.io/snippets/install-angularfire/

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
