<form class="d-flex justify-content-center align-items-center flex-column login-form" name="userResetPasswordForm" #userResetPasswordForm="ngForm" novalidate>
    <h4 class="login-logo mb-2">
        <i class="fas fa-video mr-2"></i>
        VideoWebRTC
    </h4>
    <div class="login-block">
        <h5>Reset Password</h5>
        <p class="text-center">Please enter your email address to <br> request a password reset</p>
		<div class="form-group">
			<input type="text" class="form-control" placeholder="Email" name="email" #email="ngModel" 
            [(ngModel)]="resetPasswordForm.email" required email (ngModelChange)="authService.getValidationMessage(email)">
            <div class="validation-message">{{ email.errors && email.errors.validationMessage }}</div>
		</div>

		<!-- Calling SignIn Api from AuthService -->
		<div class="form-group">
            <button type="button" class="btn btn-primary" [disabled]="userResetPasswordForm.invalid" 
                (click)="authService.ForgotPassword(resetPasswordForm.email)">
                Reset Password
            </button>
		</div>
        <div class="form-group text-center mb-0">
            <span class="link" routerLink="/sign-in">Go Back to ? Sign In</span>
        </div>
	</div>
</form>