<form class="d-flex justify-content-center align-items-center flex-column login-form" name="userLoginForm" #userLoginForm="ngForm" novalidate>
	
    <div class="login-block">
		<h4 class="login-logo mb-2">
      <img src="https://www.callhealer.uk/assets/images/CALL HEALER beyaz.png">
    </h4>
		<h5>Online Meeting Room</h5>
		
		

		<div class="form-group">
            <div class="decorated"><span>Please Login With</span></div>
		</div>

		<!-- Calling GoogleAuth Api from AuthService -->
		<div class="form-group">
			<button type="button" class="btn btn-danger" (click)="authService.GoogleAuth()">
				<i class="fab fa-google mr-1"></i>
				Continue with your Google account
			</button>
		</div>
		
       
	</div>
</form>